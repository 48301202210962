import withinRange from "../withinRange"
import { formatDateToUTCString } from "./formatDateToUTCString"

/**
 * Parses a given date string and returns a relative date description compared to a specified comparison date.
 *
 * @param date - The date string to be parsed.
 * @param compareDate - The date string to compare against. If not provided, the current date is used.
 * @param asWord - A boolean indicating whether to return the relative date as a word (e.g., "Today", "Tomorrow") or as the number of days between the dates. Defaults to true.
 * @returns A string representing the relative date description or the number of days between the dates.
 */
export const parseRelativeDates = (date: string, compareDate: string, asWord = true): string => {
    const dateObj = new Date(date)

    const today = compareDate ? new Date(compareDate) : new Date()

    const daysBetween = Math.round((+dateObj - +today) / (1000 * 60 * 60 * 24))

    if (asWord === false) return String(daysBetween)

    let dateLabel = ""

    switch (true) {
        case withinRange(daysBetween, 2, 6):
            dateLabel = dateObj.toLocaleString("en-us", { weekday: "long" })
            break
        case daysBetween >= 1 && daysBetween <= 2:
            dateLabel = "Tomorrow"
            break
        case (daysBetween < 1 && daysBetween > 0) || daysBetween === 0:
            dateLabel = "Today"
            break
        case daysBetween < 0 && daysBetween > -1:
            dateLabel = "Yesterday"
            break
        default:
            dateLabel = formatDateToUTCString(dateObj)
            break
    }

    return dateLabel as string
}
